<template>
  <div>
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :max-items="itemsPerPage"
        :data="items"
        :searchKeys="searchKeys"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="createData"
            >
              <feather-icon 
                icon="PlusIcon" 
                svg-classes="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Add Property Type</span>
            </div>
            <vs-dropdown 
              vs-custom-content 
              vs-trigger-click 
              class="cursor-pointer mb-4">
              <span class="cursor-pointer flex items-center i18n-locale">
                <img
                  :src="
                    require(`@/assets/images/flags/${$store.state.locale.currentLang}.png`)
                  "
                  :alt="$store.state.locale.currentLang"
                  class="h-4 w-5"
                >
                <span class="hidden sm:block ml-2 uppercase">
                  {{
                    $store.state.locale.currentLang
                  }}
                </span>
              </span>
              <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
                <vs-dropdown-item @click="updateLang('th')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/th.png" 
                    alt="th" >
                  &nbsp;Thai
                </vs-dropdown-item>
                <vs-dropdown-item @click="updateLang('en')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/en.png" 
                    alt="en" >
                  &nbsp;English
                </vs-dropdown-item>
                <vs-dropdown-item @click="updateLang('cn')">
                  <img 
                    class="h-4 w-5 mr-1" 
                    src="@/assets/images/flags/cn.png" 
                    alt="cn" >
                  &nbsp;Chinese
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          /> 
        </div>
        <template slot="thead">
          <vs-th sort-key="title">ประเภท Property</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <p class="brand-priority">{{ updatedAtDatetimeFormat(tr.updated_at) }}</p>
              </td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <router-link
                  :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                  class="pt-2 color-text-link"
                >
                  <feather-icon
                    icon="EditIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  />
                </router-link>
                <feather-icon
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2 cursor-pointer"
                  @click.stop="deleteData(tr.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { computed, ref, watch } from '@vue/composition-api'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'TypeListView',
  components: {
    LhDropdownPerPage,
    CustomTable
  },
  setup(props, ctx) {
    const {
      root: { $store },
    } = ctx
    const crudFunction = useCrud(ctx, 'propertyType')
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const typeFilter = ref(null)

    crudFunction.routePrefix.value = 'property-type'

    const items = computed(() => {
      const category = map(crudFunction.result.value, (item) => {
        const zone = find(item.property_category_translates, [
          'lang',
          $store.state.locale.currentLang,
        ])
        return {
          id: item.id,
          title: zone ? zone.value : item.property_category_translates[0].value,
          updated_at: item.updated_at,
          code: item.code
        }
      })

      return category.filter((cat) => cat.code !== 'ladawan')
    })

    const updateLang = (value) => {
      ctx.emit('update-lang', value)
      ctx.root.$store.dispatch('locale/changeLang', value)
    }

    const editData = (id) => {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: { id },
      })
    }

    return {
      ...crudFunction,
      itemsPerPage: 10,
      items,
      updateLang,
      updatedAtDatetimeFormat,
      typeFilter,
      editData,
      searchKeys: ['title']
    }
  },
}
</script>

<style scoped></style>
